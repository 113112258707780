export { default as FormItem } from './FormItem.vue';
export { default as FormLabel } from './FormLabel.vue';
export { default as FormControl } from './FormControl.vue';
export { default as FormMessage } from './FormMessage.vue';
export { default as FormDescription } from './FormDescription.vue';
export { default as ErrorMessage } from './FormMessage.vue';
export { useFormField } from './useFormField';
import type { InjectionKey } from 'vue';

export const FORM_ITEM_INJECTION_KEY = Symbol() as InjectionKey<string>;
