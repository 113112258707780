<script lang="ts">
import type { HTMLAttributes } from 'vue';
import { FORM_ITEM_INJECTION_KEY } from '.';
</script>

<script lang="ts" setup>
import { provide } from 'vue';
import { useId } from 'radix-vue';
import { cn } from '@laam/lib/utils';

const props = defineProps<{
	class?: HTMLAttributes['class'];
}>();

const id = useId();
provide(FORM_ITEM_INJECTION_KEY, id);
</script>

<template>
	<div :class="cn('space-y-sm', props.class)">
		<slot />
	</div>
</template>
